import { defineMessages } from "react-intl";

const backLinkMessages = defineMessages({
    backIconAlt: {
        id: "backLinkMessages.backIconAlt",
        description: "Alt text for the back icon",
        defaultMessage: "Back to",
    },
});

export default backLinkMessages;
