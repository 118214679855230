import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { defineMessages, FormattedMessage } from "react-intl";
import { withWidth } from "omse-components";
import NavButton from "../components/NavButton";
import WelcomeContent from "./landing/WelcomeContent";
import TabbedCarousel from "./landing/TabbedCarousel";
import AccessAPIs from "./landing/tabbedCarousel/AccessAPIs";
import DownloadData from "./landing/tabbedCarousel/DownloadData";
import GetInspired from "./landing/tabbedCarousel/GetInspired";
import GetStarted from "./landing/tabbedCarousel/GetStarted";
import WhatsNew from "./landing/tabbedCarousel/WhatsNew";
import PlanBanner from "./landing/PlanBanner";
import ExamplesTutorials from "./landing/ExamplesTutorials";
import GettingStarted from "./landing/GettingStarted";
import routePaths, { getLocation } from "../util/routes";
import landingImg from "./landing/img/hero-bg-vector.svg";
import landingImgMobile from "./landing/img/mobile/osmm-highways-speeds-network-southampton-small.jpg";
import styled from "@emotion/styled";
import CommunityContent from "./landing/CommunityContent";
import FadeInOutContainer from "../components/fadeInOutContainer/FadeInOutContainer";
import useFeatureCheck from "../util/useFeatureCheck";
import features from "../../shared/features";

const messages = defineMessages({
    welcomeTitle: {
        id: "Landing.welcomeTitle",
        defaultMessage: "Welcome to the OS Data Hub",
        description: "Welcome title text"
    },
    welcomeIntro1: {
        id: "Landing.welcomeIntro1",
        defaultMessage: "Great Britain’s Geospatial Data platform.",
        description: "Welcome intro text; first paragraph"
    },
    welcomeIntro2: {
        id: "Landing.welcomeIntro2",
        defaultMessage:
            "Use our APIs and data downloads to integrate OS data in your work and applications.",
        description: "Welcome intro text; second paragraph"
    },
    signUpButton: {
        id: "Landing.signUpButton",
        defaultMessage: "Sign up for free",
        description: "SignUp Button Text"
    },
    openDashboard: {
        id: "Landing.openDashboard",
        defaultMessage: "Open API dashboard",
        description: "Signed in button label"
    },
    downloadButton: {
        id: "Landing.downloadButton",
        defaultMessage: "Explore and access OpenData",
        description: "Download Button Text"
    },
    carouselTitle: {
        id: "Landing.carouselTitle",
        defaultMessage: "What you can do on the Data Hub",
        description: "Landing Carousel Heading Text"
    }
});

const LandingContainer = styled("div")`
    flex: 1 1 auto;
`;

export function LandingActions(props) {
    const actionButton = props.loggedIn
        ? {
              path: routePaths.dashboard,
              message: messages.openDashboard
          }
        : {
              path: routePaths.plans,
              message: messages.signUpButton
          };

    return (
        <>
            <NavButton
                color="primary"
                variant="contained"
                path={actionButton.path}
            >
                <FormattedMessage {...actionButton.message} />
            </NavButton>
            <NavButton
                color="primary"
                variant="outlined"
                path={routePaths.openDataDownloads}
            >
                <FormattedMessage {...messages.downloadButton} />
            </NavButton>
        </>
    );
}

export function Landing({ location, history, loading, userDetails, loggedIn }) {
    useEffect(() => {
        function fixPath() {
            if (
                !loading &&
                userDetails &&
                location.pathname !== routePaths.landing
            ) {
                const newLocation = getLocation(routePaths.landing, location);
                history.replace(newLocation);
            }
        }
        fixPath();
    }, [history, loading, location, userDetails]);

    const delFeatureEnabled = useFeatureCheck(features.DEL);
    const sampleDataEnabled = useFeatureCheck(features.SAMPLE_DATA);
    const trialDataAvailable = delFeatureEnabled || sampleDataEnabled;

    return (
        <LandingContainer>
            {!loading && (
                <>
                    <WelcomeContent
                        title={messages.welcomeTitle}
                        intro={[messages.welcomeIntro1, messages.welcomeIntro2]}
                        backgroundImage={landingImg}
                        backgroundImageMobile={landingImgMobile}
                        actionButtons={<LandingActions loggedIn={loggedIn} />}
                    />
                    <FadeInOutContainer>
                        <TabbedCarousel
                            title={messages.carouselTitle}
                            items={[
                                {
                                    title: "Access APIs",
                                    content: <AccessAPIs />
                                },
                                {
                                    title:  trialDataAvailable ? "Trial and download data" : "Download data",
                                    content: <DownloadData />
                                },
                                {
                                    title: "Get inspired",
                                    content: <GetInspired />
                                },
                                {
                                    title: "Try code examples and tutorials",
                                    content: <GetStarted />
                                },
                                {
                                    title: "See what’s new",
                                    content: <WhatsNew />
                                }
                            ]}
                        />
                    </FadeInOutContainer>
                    <FadeInOutContainer>
                        <PlanBanner />
                    </FadeInOutContainer>
                    <FadeInOutContainer>
                        <GettingStarted />
                    </FadeInOutContainer>
                    <FadeInOutContainer>
                        <ExamplesTutorials />
                    </FadeInOutContainer>
                    <CommunityContent />
                </>
            )}
        </LandingContainer>
    );
}

Landing.propTypes = {
    loggedIn: PropTypes.bool,
    userDetails: PropTypes.object,
    loading: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object
};

function mapStateToProps(state) {
    const { result, loading } = state.user.current;
    const configLoading = state.config.current.loading;
    const videos = state.videos.current;
    const currentConfig = state.config.current;

    return {
        userDetails: result,
        loading: configLoading || loading,
        loggedIn: !!(result && result.active),
        videos,
        config: currentConfig && currentConfig.result
    };
}

export default compose(withWidth(), connect(mapStateToProps))(Landing);
