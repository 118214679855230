import {useForm} from "@tanstack/react-form";
import {
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@mui/material";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {createUseStyles} from "react-jss";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {ExternalLink, LinkButton} from "omse-components";
import routePaths, {logIn, privacyPolicyAddress} from "../../../../util/routes";
import {postLead} from "../../../../modules/downloads";
import {useMutation} from "@tanstack/react-query";
import {LOCAL_STORAGE_KEYS} from "../../../../constants/constants";
import {cookieSettingToIndex, isCookieControlSettingAccepted} from "../../../../util/cookie-control";
import { isValidPhoneNumber } from "libphonenumber-js";

const messages = defineMessages({
    title: {
        id: 'SampleDataForm.title',
        defaultMessage: 'Sample Data download',
        description: 'Title for OS Sample Data downloads form'
    },
    description: {
        id: 'SampleDataForm.description',
        defaultMessage: 'To download our sample data, please provide a few details below. If you <button>log in</button> or <a>sign up</a> you will only need to do this once.',
        description: 'Description for OS Sample Data downloads form'
    },
    loggedInDescription: {
        id: 'SampleDataForm.loggedInDescription',
        defaultMessage: 'To download our sample data, please provide a few details below.',
        description: 'Description for OS Sample Data downloads form when user is logged in'
    },
    firstNameTitle:{
        id: 'SampleDataForm.firstNameTitle',
        defaultMessage: 'First name',
        description: 'Field Title for First name'
    },
    firstNameRequired:{
        id: 'SampleDataForm.firstNameRequired',
        defaultMessage: "Please enter your first name",
        description: 'First name required message'
    },
    lastNameTitle:{
        id: 'SampleDataForm.lastNameTitle',
        defaultMessage: 'Last name',
        description: 'Field Title for Last name'
    },
    lastNameRequired:{
        id: 'SampleDataForm.lastNameRequired',
        defaultMessage: 'Please enter your last name',
        description: 'Last name required message'
    },
    jobTitleTitle:{
        id: 'SampleDataForm.JobTitleTitle',
        defaultMessage: 'Job title',
        description: 'Field Title for Job title'
    },
    jobTitleRequired:{
        id: 'SampleDataForm.JobTitleRequired',
        defaultMessage: 'Please enter your job title',
        description: 'Job title required message'
    },
    emailAddressTitle:{
        id: 'SampleDataForm.emailAddressTitle',
        defaultMessage: 'Email address',
        description: 'Field Title for Email'
    },
    emailAddressRequired:{
        id: 'SampleDataForm.emailAddressRequired',
        defaultMessage: 'Please enter your email address',
        description: 'Email required message'
    },
    emailAddressInvalid:{
        id: 'SampleDataForm.emailAddressInvalid',
        defaultMessage: 'Please enter a valid email address with the format example@domain.co.uk',
        description: 'Email invalid message'
    },
    phoneNumberTitle:{
        id: 'SampleDataForm.phoneNumberTitle',
        defaultMessage: 'Phone number',
        description: 'Field Title for Phone number'
    },
    phoneNumberRequired:{
        id: 'SampleDataForm.phoneNumberRequired',
        defaultMessage: 'Please enter your phone number',
        description: 'Phone number required message'
    },
    phoneNumberInvalid:{
        id: 'SampleDataForm.phoneNumberInvalid',
        defaultMessage: 'Please enter a valid phone number consisting of numbers and these special characters +-()',
        description: 'Phone number invalid message'
    },
    organisationTitle:{
        id: 'SampleDataForm.organisationTitle',
        defaultMessage: 'Organisation',
        description: 'Field Title for Organisation'
    },
    organisationRequired:{
        id: 'SampleDataForm.organisationRequired',
        defaultMessage: 'Please enter your organisation',
        description: 'Organisation required message'
    },
    organisationTypeTitle:{
        id: 'SampleDataForm.organisationTypeTitle',
        defaultMessage: 'Organisation type',
        description: 'Field Title for Organisation type'
    },
    organisationTypeRequired:{
        id: 'SampleDataForm.organisationTypeRequired',
        defaultMessage: 'Please enter your organisation type',
        description: 'Organisation type required message'
    },
    publicSectorOrganisation:{
        id: 'SampleDataForm.publicSectorOrganisation',
        defaultMessage: 'Public sector organisation',
        description: 'Organisation type Public sector organisation'
    },
    corporationBusiness:{
        id: 'SampleDataForm.corporationBusiness',
        defaultMessage: 'Corporation/business',
        description: 'Organisation type Corporation/business'
    },
    soleTrader:{
        id: 'SampleDataForm.soleTrader',
        defaultMessage: 'Sole trader',
        description: 'Organisation type Sole trader'
    },
    education:{
        id: 'SampleDataForm.education',
        defaultMessage: 'Education',
        description: 'Organisation type Education'
    },
    privateNonCommercial :{
        id: 'SampleDataForm.privateNonCommercial',
        defaultMessage: 'Private individual (non-commercial use)',
        description: 'Organisation type Private individual (non-commercial use)'
    },
    other :{
        id: 'SampleDataForm.other',
        defaultMessage: 'Other',
        description: 'Organisation type Other'
    },
    acceptTermsMessage:{
        id: 'SampleDataForm.acceptedTermsMessage',
        defaultMessage: 'I have read and accepted the <a>Sample Data trial licence</a>',
        description: 'Accept Terms message'
    },
    acceptTermsLinkMessage:{
        id: 'SampleDataForm.acceptTermsLinkMessage',
        defaultMessage: 'I have read and accepted the Sample Data trial licence',
        description: 'Accept Terms link message'
    },
    acceptTermsAriaLabelMessage:{
        id: 'SampleDataForm.acceptTermsAriaLabelMessage',
        defaultMessage: 'Open the Sample Data trial licence',
        description: 'Accept Terms link message'
    },
    acceptTermsRequiredMessage:{
        id: 'SampleDataForm.acceptTermsRequiredMessage',
        defaultMessage: 'To use our sample data, please accept the Sample Data trial licence.',
        description: 'Accept Terms required error message'

    },
    termsMessage:{
        id: 'SampleDataForm.termsMessage',
        defaultMessage: 'By downloading the sample data, you agree to our sample data terms and conditions.',
        description: 'Terms message'
    },
    privacyPolicyText:{
        id: 'SampleDataForm.privacyPolicyText',
        defaultMessage: 'All of the information that you provide will be used in accordance with our <a>privacy policy</a>.',
        description: 'Terms link text'
    },
    privacyPolicyLinkText: {
        id: 'SampleDataForm.privacyPolicyLinkText',
        defaultMessage: 'Open the privacy policy (Opens in a new tab)',
        description: 'Terms link text'
    },
    submitButtonText: {
        id: 'SampleDataForm.submitButtonText',
        defaultMessage: 'Get sample data',
        description: 'Submit button text'
    },
    cancelButtonText: {
        id: 'SampleDataForm.cancelButtonText',
        defaultMessage: 'Cancel',
        description: 'Cancel button text'
    },
    fieldTooLongError: {
        id:'sampleDataForm.fieldTooLongError',
        defaultMessage: '{fieldName} must be no more than {maxLength} characters long',
        description: 'Field too long error message'
    }
});

const useStyles = createUseStyles(theme => ({
    formContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5)
    },
    checkboxContainer: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    errorField: {
        backgroundColor: "#faeef1"
    },
    errorText: {
        color: "#c63d54",
        fontWeight: 400,
        fontSize: "0.875rem"
    },
    formPadding: {
        padding: theme.spacing(1)
    },
    loginTextButton: {
        minWidth: '0px',
        verticalAlign: 'baseline'
    },
    additionalBottomPadding: {
        paddingBottom: theme.spacing(1)
    },
    submitButton: {
        minWidth: theme.spacing(19),
    }
}));

const EMAIL_ADDRESS_REGEXP = /^[^@]+@[^@]+\.[^@]+$/;

// Default form values when there is no user
const defaultFormValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    emailAddress: '',
    phoneNumber: '',
    organisation: '',
    organisationType: ''
};

// Default form values when user is logged in
function userToSampleFormData(user){
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle || '',
        emailAddress: user.email,
        phoneNumber: user.telephoneNumber || '',
        organisation: user.org,
        organisationType: ''
    };
}

export function SampleDataForm({setShowForm, setDownloads, user, productId}) {
    const classes = useStyles();
    const intl = useIntl();
    // ToDo: Add isError and handle errors correctly
    const {mutate, isPending} = useMutation({
        mutationFn: value => postLead(value, productId),
        onSuccess:(res)=>{
            setDownloads(res);
            setShowForm(false);
        },
        onError:(err)=>{
            console.log(err);
        }
    });

    function getFormValues() {
        // if the user has accepted functional cookies and stored their form response in local storage, use it!
        const localStorageFormDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.sampleDataForm);
        if (localStorageFormDetails) {
            return JSON.parse(localStorageFormDetails);
        }

        // otherwise, if the user is logged-in, pre-populate as much as we can from the user info
        if (user) {
            return userToSampleFormData(user);
        }

        // or finally, return an empty form
        return defaultFormValues;
    }

    const form = useForm({
        defaultValues: {
            ...getFormValues(),
            acceptedTermsDate: null, // users always have to re-tick this box for now
        },
        onSubmit: async ({value}) => {
            // Store form response in localStorage if the user has consented
            if (isCookieControlSettingAccepted(cookieSettingToIndex.functionality)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.sampleDataForm, JSON.stringify(value))
            }

            // Augment the form values with some extra info we need like the userId
            const postBody = {
                ...value,
                userId: user ? user.id : '',
                acceptedTermsDate: new Date().toISOString()
            };

            // ToDo: use the form values to get the downloads for a given object id as part of 382238
            console.log(postBody);
            mutate(postBody);
        }
    });

    function validatePhoneNumber(value) {
        if (value.length < 1) return intl.formatMessage(messages.phoneNumberRequired);
        if (!isValidPhoneNumber(value, 'GB')) return intl.formatMessage(messages.phoneNumberInvalid);
    }

    const termsLink = "https://www.ordnancesurvey.co.uk/documents/licences/sample-data-licence.pdf";

    const errorMessage = ({message}) => {
        return <span aria-live={"polite"} role={"alert"} className={classes.errorText}>{message}</span>
    }

    const formDescription = user
        ? <FormattedMessage {...messages.loggedInDescription} />
        : <FormattedMessage {...messages.description} values={{
            a: msg => <a type="generic" href={routePaths.plans}>{msg}</a>,
            button: chunks => (
                <LinkButton key='login'
                            onClick={logIn}
                            size='normal' variant='text'
                            className={classes.loginTextButton}
                            id="logInButton">
                    {chunks}
                </LinkButton>
            )
        }}/>;

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
            }}>
            <DialogTitle><FormattedMessage {...messages.title} /></DialogTitle>
            <DialogContent className={classes.formContentContainer}>
                <DialogContentText className={classes.additionalBottomPadding}>
                    {formDescription}
                </DialogContentText>
                <form.Field
                    name="firstName"
                    validators={{
                        onBlur: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.firstNameRequired);
                            if (value.length > 50) return intl.formatMessage(messages.fieldTooLongError, {fieldName: 'First name', maxLength: 50});
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.firstNameTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="lastName"
                    validators={{
                        onBlur: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.lastNameRequired);
                            if (value.length > 50) return intl.formatMessage(messages.fieldTooLongError, {fieldName: 'Last name', maxLength: 50});
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.lastNameTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="jobTitle"
                    validators={{
                        onBlur: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.jobTitleRequired);
                            if (value.length > 137) return intl.formatMessage(messages.fieldTooLongError, {fieldName: 'Job title', maxLength: 137});
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.jobTitleTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="emailAddress"
                    validators={{
                        onBlur: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.emailAddressRequired);
                            if (!EMAIL_ADDRESS_REGEXP.test(value)) return intl.formatMessage(messages.emailAddressInvalid);
                            if (value.length > 100) return intl.formatMessage(messages.fieldTooLongError, {fieldName: 'Email address', maxLength: 100});
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.emailAddressTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="phoneNumber"
                    validators={{
                        onMount: ({value}) => {
                            // The DataHub phone number is subject to less strict validation than in this form.
                            // This code ensures that if the phone number is pre-populated from the user object then
                            // it is validated onMount and shows the validation error in the UI straight away. It is
                            // conditional on value so that we don't immediately show the phoneNumberRequired error
                            // if the phone number is not pre-populated.
                            if (value) {
                                return validatePhoneNumber(value);
                            }
                        },
                        onBlur: ({fieldApi, value}) => {
                            // cleanup the onMount error the first to be run onBlur
                            const errorMap = fieldApi.state.meta.errorMap;
                            delete errorMap.onMount;

                            return validatePhoneNumber(value);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.phoneNumberTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="organisation"
                    validators={{
                        onBlur: ({value}) => {
                            if (value.length < 1) return intl.formatMessage(messages.organisationRequired);
                            if (value.length > 100) return intl.formatMessage(messages.fieldTooLongError, {fieldName: 'Organisation', maxLength: 100});
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.organisationTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}/>
                    )}/>

                <form.Field
                    name="organisationType"
                    validators={{
                        onBlur: ({value}) => {
                            if (value === "") return intl.formatMessage(messages.organisationTypeRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            select
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.organisationTypeTitle)}
                            defaultValue={intl.formatMessage(messages.organisationTypeTitle)}
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({message: field.state.meta.errors})}
                            disabled={isPending}>
                            <MenuItem
                                value="Public sector organisation"><FormattedMessage {...messages.publicSectorOrganisation}/></MenuItem>
                            <MenuItem value="Corporation/business"><FormattedMessage {...messages.corporationBusiness}/></MenuItem>
                            <MenuItem value="Sole trader"><FormattedMessage {...messages.soleTrader}/></MenuItem>
                            <MenuItem value="Education"><FormattedMessage {...messages.education}/></MenuItem>
                            <MenuItem
                                value="Private individual (non-commercial use)"><FormattedMessage {...messages.privateNonCommercial}/></MenuItem>
                            <MenuItem value="Other"><FormattedMessage {...messages.other}/></MenuItem>
                        </TextField>
                    )}/>
                <form.Field
                    validators={{
                        onChange: ({value}) => {
                            if (!value) return intl.formatMessage(messages.acceptTermsRequiredMessage);
                        }
                    }}
                    name="acceptedTermsDate"
                    disabled={isPending}
                    children={field => (
                        <div
                            className={classNames(field.state.meta.errors.length > 0 && classes.errorField, classes.formPadding)}>
                            <div className={classNames(classes.checkboxContainer, classes.formContentItem)}>
                                <Checkbox
                                    aria-label={intl.formatMessage(messages.acceptTermsLinkMessage)}
                                    checked={field.state.value}
                                    onChange={(e) => field.handleChange(e.target.checked)}
                                    onBlur={field.handleBlur}
                                    required={true}
                                    disabled={isPending}
                                />
                                <Typography>
                                    <FormattedMessage {...messages.acceptTermsMessage} values={{
                                        a: chunks => (
                                            <ExternalLink
                                                ariaLabel={intl.formatMessage(messages.acceptTermsAriaLabelMessage)}
                                                type="generic"
                                                href={termsLink}
                                                message={chunks}
                                            />
                                        )
                                    }}/>
                                </Typography>
                            </div>
                            {field.state.meta.errors.length > 0 && errorMessage({message: field.state.meta.errors})}
                        </div>
                    )}/>
                <DialogContentText>
                    <FormattedMessage {...messages.privacyPolicyText} values={{
                        a: msg => (
                            <ExternalLink
                                href={privacyPolicyAddress}
                                type={"generic"}
                                aria-label={intl.formatMessage(messages.privacyPolicyLinkText)}
                                message={msg}
                            />
                        )
                    }}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowForm(false)}>
                    <FormattedMessage {...messages.cancelButtonText} />
                </Button>
                <Button className={classNames(classes.submitButton)} variant="contained" type="submit">
                    {isPending ?
                        <CircularProgress color={"inherit"} size={24} data-testid="loading-spinner"/> :
                        <FormattedMessage {...messages.submitButtonText} />
                    }
                </Button>
            </DialogActions>
        </form>
    )
}

SampleDataForm.propTypes = {
    setShowForm: PropTypes.func
}