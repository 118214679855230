import React, {useCallback, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {defineMessages} from 'react-intl';
import {osColour, InputBox, InputLabel, simulateExternallyAppliedFocusStyle} from 'omse-components';
import FieldValidationMessage from './fieldValidation/FieldValidationMessage';
import {FormattedMessage, useIntl} from 'react-intl';
import {setupPaymentDetails} from "../../modules/payments/actions";
import classNames from 'classnames';
import AddressDetails from './setupPaymentDialog/AddressDetails';
import useActionIdSelector from "../../hooks/useActionIdSelector";
import {Typography} from '@mui/material';
import cardSecurityCode from './img/card-security-code.png';
import cardSecurityCodeAmex from './img/card-security-code-amex.png';
import amex from './img/cards/amex.png';
import visa from './img/cards/visa.png';
import mastercard from './img/cards/mastercard.png';
import jcb from './img/cards/jcb.gif';
import maestro from './img/cards/ms_acc.svg';
import userConstants from '../../constants/user';

const messages = defineMessages({
    name: {
        id: 'PaymentDetailsForm.name',
        defaultMessage: 'Name as it appears on card',
        description: 'Label for the name field'
    },
    cardHolderName: {
        id: 'PaymentDetailsForm.cardHolderName',
        defaultMessage: 'Card holder name',
        description: 'Placeholder text for the name field'
    },
    phone: {
        id: 'PaymentDetailsForm.phone',
        defaultMessage: 'Phone number',
        description: 'Label for the phone number field'
    },
    cardNumber: {
        id: 'PaymentDetailsForm.cardNumber',
        defaultMessage: 'Card number',
        description: 'Label for the card number field'
    },
    expiryDate: {
        id: 'PaymentDetailsForm.expiryDate',
        defaultMessage: 'Expiry date',
        description: 'Label for the expiry date field'
    },
    exampleExpiryDate: {
        id: 'PaymentDetailsForm.exampleExpiryDate',
        defaultMessage: 'For example, 06/22',
        description: 'Label for the expiry date example'
    },
    securityCode: {
        id: 'PaymentDetailsForm.securityCode',
        defaultMessage: 'Card security code',
        description: 'Label for the card security code'
    },
    exampleSecurityCode: {
        id: 'PaymentDetailsForm.exampleSecurityCode',
        defaultMessage: '{amex, select, false {The last 3 digits on the back} other {4 digits on the front}}',
        description: 'Label for the security code example'
    },
    billingAddress: {
        id: 'PaymentDetailsForm.billingAddress',
        defaultMessage: 'Billing address',
        description: 'Billing address label'
    },
    invalidCardholderName: {
        id: 'PaymentDetailsForm.invalidCardholderName',
        defaultMessage: 'Invalid card holder name',
        description: 'Invalid cardholder name label'
    },
    invalidExpiry: {
        id: 'PaymentDetailsForm.invalidExpiry',
        defaultMessage: 'Invalid expiration date',
        description: 'Expiry date label'
    },
    invalidSecurityCode: {
        id: 'PaymentDetailsForm.invalidSecurityCode',
        defaultMessage: 'Invalid security code',
        description: 'Invalid security code label'
    },
    invalidPan: {
        id: 'PaymentDetailsForm.invalidPan',
        defaultMessage: 'Invalid card number',
        description: 'Invalid card number label'
    },
    acceptCardTypes: {
        id: 'PaymentDetailsForm.acceptCardTypes',
        defaultMessage: 'We accept these card types',
        description: 'Accept card types label'
    },
    unsupportedCardType: {
        id: 'PaymentDetailsForm.unsupportedCardType',
        defaultMessage: 'We do not accept this card type',
        description: 'Message shown when we do not accept a card type'
    }
})

const cards = [{type: 'amex', icon: amex}, {type: 'visa', icon: visa}, {type: 'mastercard', icon: mastercard}, {type: 'maestro', icon: maestro}, {type: 'jcb', icon: jcb}];

const wpFont = "Arial, Helvetica, sans-serif";

const styles = createUseStyles(theme => ({
    form: {
        [theme.breakpoints.down('sm')]: {
            width: 'auto'
        },
        display: 'flex',
        flexDirection: 'column',
        '& h2': {
            marginTop: theme.spacing(4)
        },
        '& $field': {
            position: 'relative'
        },
        '& $fieldMessage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            alignItems: 'center',
            pointerEvents: 'none'
        },
        '& #cardHolderNameInput': {
            fontFamily: wpFont,
            lineHeight: 1.375
        }
    },
    submit: {
        display: 'none'
    },
    field: {
        '& iframe': {
            height: '40px !important',
            padding: '6px 12px 3px 12px !important',
            boxSizing: 'border-box',
            border: `solid ${osColour.neutral.stone} 1px !important`,
            borderRadius: 3,
            marginBottom: 0,
            position: 'relative' // prevents clipped focus outline issue in safari
        },
        // state classes are added based on user behaviour
        '&.is-onfocus iframe': {
            ...simulateExternallyAppliedFocusStyle
        },
        '&.cardUnsupported': {
            '& iframe': {
                border: `solid ${osColour.status.error} 1px !important`
            },
            '& $invalid': {
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                alignItems: 'start'
            }
        },
        '&.is-invalid': {
            '& iframe': {
                border: `solid ${osColour.status.error} 1px !important`
            },
            '& $invalid': {
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                alignItems: 'start'
            }
        },
        '&.is-valid:not(.cardUnsupported)': {
            '& $valid': {
                display: 'flex',
                '& svg': {
                    marginRight: theme.spacing(1)
                },
                flexDirection: 'row-reverse'
            }
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        '& label': {
            marginBottom: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            '& > section:first-child': {
                marginRight: theme.spacing(3)
            }
        }
    },
    fieldExample: {
        color: osColour.neutral.stone,
        marginBottom: theme.spacing(0.5)
    },
    cardSecurityCode: {
        display: 'flex',
        flexDirection: 'row',
        '& $field': {
            marginRight: theme.spacing(1.5)
        }
    },
    securityCodeIcon: {
        '& img': {
            height: 39,
            width: 59
        }
    },
    validates: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginBottom: theme.spacing(1),
        '&:focus': {
            outlineStyle: 'none'
        }
    },
    fadeCards: {
        '& img': {
            opacity: 0.5,
            filter: 'grayscale(100%)'
        }
    },
    acceptedCards: {
        '& img': {
            height: 39,
            width: 59,
            marginRight: theme.spacing(1)
        },
        '& $active': {
            opacity: 1,
            filter: 'none'
        }
    },
    active: {},
    fieldMessage: {},
    valid: {},
    invalid: {}
}));

export default function PaymentDetailsForm(props) {
    const {onValid, onError, onWorking, confirmRef, onClose} = props;
    const classes = styles();
    const classesValidation = {fieldMessage: classes.fieldMessage, valid: classes.valid, invalid: classes.invalid};
    const intl = useIntl();
    const [worldpayError, setWorldpayError] = useState(null);
    const [cardDetailsValid, setCardDetailsValid] = useState(false);
    const [cardholderName, setCardHolderName] = useState(null);
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [cardType, setCardType] = useState(null);
    const {checkoutIdentity} = useSelector(state => state.config.current.result);
    const [{result, error}, dispatch] = useActionIdSelector('payments.setupPaymentDetails');

    let paymentError = error;
    if(paymentError && paymentError.httpResponse) {
        paymentError = paymentError.httpResponse;
    }

    let cardUnsupported = cardType && !cards.find(c => c.type === cardType);

    // We use a ref to hold the Worldpay checkout instance, so that we can get to the current value when we need to.
    // We use a similar approach for the cleanup we need to do when the dialog closes.
    const checkoutInstance = useRef();
    const cleanupHandler = useRef();

    // If a new worldpay error occurs, report it
    useEffect(() => {
        if (worldpayError) {
            onError(worldpayError);
            onWorking(false);
        }
    }, [onWorking, onError, worldpayError]);

    // If a new payment error occurs, report it
    useEffect(() => {
        if(paymentError) {
            onError(paymentError);
            onWorking(false);
        }
    }, [onWorking, onError, paymentError]);

    // Keep the valid state in sync with the data in this component. As this is triggered any time that the
    // info on the form changes it is also a good time to clear any stored errors, so that the user can edit
    // their details and resubmit.
    useEffect(() => {
        const valid = cardDetailsValid && cardholderName !== '' && address && phone && cardType && !cardUnsupported;
        onValid(valid);
        onError(null);
    }, [onValid, onError, cardDetailsValid, cardholderName, address, cardType, cardUnsupported, phone]);

    // Clean up our listeners when we unmount
    useEffect(() => {
        return function cleanUp() {
            if(cleanupHandler.current) {
                cleanupHandler.current();
            }
        }
    }, []);

    // Store a function that the higher-up components can use when the confirmation button is clicked.
    confirmRef.current = () => {
        onWorking(true);

        // The worldpay checkout SDK seems to have a bug, where they keep hold of the callback that we
        // provide to them, so the second time we use the SDK we get called back twice. To protect us
        // from that we setup the real callback that we want here, and once it has been called once we
        // reset the callback variable so that it cannot be called again
        let callback = function(error, sessionState) {
            if (error) {
                setWorldpayError(error);
                return;
            }
            // We now have session state that we need to send to the backend
            dispatch(setupPaymentDetails('', cardholderName, sessionState, address, phone));
        }

        checkoutInstance.current.generateSessionState(function(error, sessionState) {
            if(callback) {
                callback(error, sessionState);
                callback = null;
            }
        });
    };

    function isAmex() {
        return cardType === 'amex';
    }

    function isCardType() {
        return cardType && cardType !== '';
    }

    const setup = useCallback(form => {
        if(form !== null) {
            function formEventHandler(event) {
                setCardDetailsValid(event.detail['is-valid']);
            }
            function cardtypeChangeHandler(event) {
                setCardType(event.detail.type);
            }

            form.addEventListener('wp:form:change', formEventHandler);
            form.addEventListener('wp:card:change', cardtypeChangeHandler);

            cleanupHandler.current = () => {
                form.removeEventListener('wp:form:change', formEventHandler);
                form.removeEventListener('wp:card:change', cardtypeChangeHandler);
            }

            /* global Worldpay */
            Worldpay.checkout.init({
                id: checkoutIdentity,
                form: "#card-form",
                fields: {
                    pan: {
                        selector: "#card-pan",
                        placeholder: "4444333322221111"
                    },
                    cvv: {
                        selector: "#card-cvv",
                        placeholder: "123"
                    },
                    expiry: {
                        selector: "#card-expiry",
                        placeholder: "MM/YY"
                    }
                },
                styles: {
                    "input": {
                        "font-family": wpFont,
                        "color": osColour.neutral.charcoal
                    },
                    "input#pan": {
                        "font-size": '16px'
                    },
                    "input::placeholder": {
                        "color": osColour.neutral.rock,
                        "font-style": "italic"
                    }
                }
            },
            function (error, checkout) {
                if (error) {
                    setWorldpayError(error);
                    return;
                }
                checkoutInstance.current = checkout;
            });
        }
    }, [checkoutIdentity]);

    if (result) {
        // We have just set up a complete set of payment details. If form is in a dialog, it can now close
        if (onClose) {
            onClose();
        }
        return null;
    }

    // cardholder name is initially null, so no validation error message is displayed until it is edited
    const cardholderNameInvalid = (cardholderName !== null && cardholderName.trim().length === 0);
    const cardholderNameValid = (cardholderName && cardholderName.trim().length > 0);

    // The phone number is initially null, so as soon as it is non-null we know that the field has been edited, and we
    // can now start validating it.
    let phoneValid = userConstants.phoneMatcher.test(phone);
    let phoneInvalid = phone !== null && !phoneValid;
    let phoneError = userConstants.messages.phoneValidationError;
    if(phone && phone.length > 30) {
        phoneError = userConstants.messages.phoneLengthError;
        phoneInvalid = true;
        phoneValid = false;
    }

    const acceptedCardClasses = [classes.acceptedCards];
    if (isCardType()) {
        acceptedCardClasses.push(classes.fadeCards);
    }


    let invalidCardMessage = messages.invalidPan;
    if(cardUnsupported) {
        invalidCardMessage = messages.unsupportedCardType;
    }

    return  <form ref={setup} className={classNames(classes.card, classes.form)} id="card-form">
        <div className={classes.formFields}>
            <InputLabel htmlFor="card-pan">
                <FormattedMessage {...messages.cardNumber}/>
            </InputLabel>
            <section id="card-pan" className={classNames(classes.field, classes.validates, { cardUnsupported: cardUnsupported })}>
                <FieldValidationMessage invalid={invalidCardMessage} classes={classesValidation} />
            </section>

            <div className={classNames(acceptedCardClasses)}>
                {cards.map(card => <img key={card.type} src={card.icon} alt={`${card.type} icon`} className={(cardType === card.type && classes.active) || null} />)}
                <Typography variant="caption" component='div' className={classes.fieldExample}>
                    <FormattedMessage {...messages.acceptCardTypes}/>
                </Typography>
            </div>

            <InputLabel htmlFor="cardHolderNameInput">
                <FormattedMessage {...messages.name}/>
            </InputLabel>
            <section id="cardHolderName"
                className={classNames(classes.field, {'is-valid': cardholderNameValid, 'is-invalid': cardholderNameInvalid})}>
                <InputBox id={'cardHolderNameInput'}
                        onChange={event => setCardHolderName(event.target.value)}
                        value={cardholderName? cardholderName : ''}
                        placeholder={intl.formatMessage(messages.cardHolderName)}
                        className={classNames(classes.validates, {'is-valid': cardholderName})}
                        fullWidth={true}
                        invalid={cardholderNameInvalid}
                />
                <FieldValidationMessage invalid={messages.invalidCardholderName} classes={classesValidation} />
            </section>

            <section className={classes.row}>
                <section>
                    <InputLabel htmlFor="card-expiry">
                        <FormattedMessage {...messages.expiryDate}/>
                    </InputLabel>
                    <Typography variant="caption" component='div' className={classes.fieldExample}>
                        <FormattedMessage {...messages.exampleExpiryDate}/>
                    </Typography>
                    <section id="card-expiry" className={classNames(classes.field, classes.validates)}>
                        <FieldValidationMessage invalid={messages.invalidExpiry} classes={classesValidation} />
                    </section>
                </section>

                <section>
                    <InputLabel htmlFor="card-cvv">
                        <FormattedMessage {...messages.securityCode}/>
                    </InputLabel>
                    <Typography variant="caption" component='div' className={classes.fieldExample}>
                        <FormattedMessage {...messages.exampleSecurityCode} values={{amex: isAmex()}} />
                    </Typography>
                    <div className={classes.cardSecurityCode}>
                        <section id="card-cvv" className={classNames(classes.field, classes.validates)}>
                            <FieldValidationMessage invalid={messages.invalidSecurityCode} classes={classesValidation} />
                        </section>
                        <div className={classes.securityCodeIcon}>
                            <img src={isAmex()? cardSecurityCodeAmex : cardSecurityCode} alt={'Card security code icon'} />
                        </div>
                    </div>
                </section>
            </section>
        </div>

        <div className={classes.formFields}>
            <Typography variant='h3' component='h2'>
                <FormattedMessage {...messages.billingAddress} />
            </Typography>
            <InputLabel htmlFor="phoneInput">
                <FormattedMessage {...messages.phone}/>
            </InputLabel>
            <section id="phone"
                     className={classNames(classes.field, {'is-valid': phoneValid, 'is-invalid': phoneInvalid})}>
                <InputBox id='phoneInput'
                          onChange={event => setPhone(event.target.value)}
                          value={phone || ''}
                          className={classNames(classes.validates, {'is-valid': phoneValid})}
                          fullWidth={true}
                          invalid={phoneInvalid}
                          type='tel'
                />
                <FieldValidationMessage invalid={phoneError} classes={classesValidation} />
            </section>
            <AddressDetails onAddressEntered={setAddress}/>
        </div>
    </form>
}

PaymentDetailsForm.propTypes = {
    confirmRef: PropTypes.object.isRequired,
    onValid: PropTypes.func.isRequired,
    onWorking: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onClose: PropTypes.func
}
