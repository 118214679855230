import React,  {useState, useRef}  from 'react';
import PropTypes from 'prop-types';
import {CommonDialog, CommonDialogActions} from 'omse-components';
import {defineMessages} from 'react-intl';
import PaymentDetailsForm from './PaymentDetailsForm';
import PaymentDetailsFormAlt from './PaymentDetailsFormAlt';
import {createUseStyles} from 'react-jss';
import useFeatureCheck from '../../util/useFeatureCheck';
import {ALT_SIGNUP_UI} from '../../../shared/features';

const messages = defineMessages({
    dialogTitle: {
        id: 'SetupPaymentDialog.dialogTitle',
        defaultMessage: 'Payment card details',
        description: 'Title for the setup payment details dialog'
    },
    submit: {
        id: 'SetupPaymentDialog.submit',
        defaultMessage: 'Submit Payment Details',
        description: 'Label for the submit payment details button'
    }
});

const altMessages = defineMessages({
    dialogTitle: {
        id: 'SetupPaymentDialog.alt.dialogTitle',
        defaultMessage: 'Payment card details',
        description: 'Title for the setup payment details dialog'
    },
    submit: {
        id: 'SetupPaymentDialog.alt.submit',
        defaultMessage: 'Save Payment Method',
        description: 'Label for the save payment method button'
    }
});

const useStyles = createUseStyles(theme => ({
    content: {
        paddingRight: theme.spacing(6.5),
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1)
        }
    }
}));

export default function SetupPaymentDialog({onClose}) {
    const classes = useStyles();
    const [valid, setValid] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const confirmRef = useRef();

    const altSignupFeatureFlag = useFeatureCheck(ALT_SIGNUP_UI);

    const actions = <CommonDialogActions
        onClose={onClose}
        confirmLabel={altSignupFeatureFlag ? altMessages.submit : messages.submit}
        onConfirm={() => confirmRef.current()}
        confirmAllowed={valid && !error}
        working={working && !error}
    />;

    return <CommonDialog
        onClose={onClose}
        title={altSignupFeatureFlag ? altMessages.dialogTitle : messages.dialogTitle}
        actions={actions}
        error={error}
        classes={{content: classes.content}}
    >
        {altSignupFeatureFlag
            ? <PaymentDetailsFormAlt
                onValid={setValid}
                onWorking={setWorking}
                onError={setError}
                confirmRef={confirmRef}
                onClose={onClose}
            />
            : <PaymentDetailsForm
                onValid={setValid}
                onWorking={setWorking}
                onError={setError}
                confirmRef={confirmRef}
                onClose={onClose}
            />
        }

    </CommonDialog>
}

SetupPaymentDialog.propTypes = {
    onClose: PropTypes.func.isRequired
}
