import {SELECT_ORG, SET_ORG_FROM_SEARCH, SET_SEARCH_FROM_ORG} from "./action";
import {modules} from 'omse-components';
import queryString from 'query-string';
import {getUserOrganisationById} from '../../util/organisation';
import {selectOrganisation} from './action';

const {setPermissions} = modules.actions.user;

export function selectOrganisationReducer(state = {
    id: null, 
    uninitialised: true
}, action) {
    switch (action.type) {
        case (SELECT_ORG):
            return ({id: action.organisation.value});
        default:
            return state;
    }
}

const initActions = [SET_ORG_FROM_SEARCH, SET_SEARCH_FROM_ORG];
const activeActions = [SELECT_ORG, ...initActions];

const shouldDispatchSelect = (org, user, search) => {
    return !org || (org && (org.id !== search.orgId || org.permissions !== user.permissions));
}

export const selectOrganisationMiddleware = store => next => action => {
    if (activeActions.includes(action.type)) {
        const state = store.getState();
        const user = state.user.current && state.user.current.result;
        if (user) {
            const history = state.history;
            const location = history.location;

            if (history && location) {
                if (action.type === SELECT_ORG && action.organisation) {
                    if (action.organisation.permissions) {
                        store.dispatch(setPermissions(action.organisation.permissions));
                    }

                    if (action.organisation.value) {
                        const search = queryString.parse(location.search);
                        
                        // Remove survey feedback parameters
                        delete search.workspacefeedback;
                        
                        history.replace({
                            ...location,
                            search: queryString.stringify({...search, orgId: action.organisation.value})
                        });
                    }
                }

                if (initActions.includes(action.type)) {
                    const organisation = state.organisation.current;
                    const search = queryString.parse(location.search);
                    const hasOrgIdParam = Object.keys(search).indexOf('orgId') !== -1;
    
                    if (action.type === SET_ORG_FROM_SEARCH) {
                        if (hasOrgIdParam && shouldDispatchSelect(organisation, user, search)) {
                            const org = getUserOrganisationById(user, search.orgId);
                            if (org) {
                                store.dispatch(selectOrganisation(org));
                            }
                        } else if (organisation.uninitialised) {
                            store.dispatch(selectOrganisation({value: null}));
                        }
                    }

                    if (action.type === SET_SEARCH_FROM_ORG) {
                        if (!hasOrgIdParam && organisation && organisation.id) {
                            history.replace({
                                ...location,
                                search: queryString.stringify({...search, orgId: organisation.id})
                            });
                        }
                    }
                }
            }
        }
    }
    return next(action);
};
