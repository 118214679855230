import Card from "../../../components/card/Card";
import VideoContent from '../../../components/video/VideoContent';
import { InternalLink, ExternalLink } from 'omse-components';
import { defineMessages, useIntl } from 'react-intl';
import accessPremium from "../img/addressbase-premium-1-large.jpg";
import del from "../img/osmm-topo-cardiff.jpg";
import openData from "../img/open-roads-base-zoomstack-place3-large.jpg";
import openDataSmall from "../img/open-roads-base-zoomstack-place3-small.jpg";
import CardContainer, { gridGap } from "../CardContainer";
import { singleColBreakpoint, horizontalPadding } from '../ContentContainer';
import features from "../../../../shared/features";
import useFeatureCheck from "../../../util/useFeatureCheck";
import useDelEligibility from "../../../hooks/useDelEligibility";
import { useSelector } from "react-redux";
import { hasPartnerCatalogue } from "../../../util/permissions";
import { USER_PREMIUM_DATA_PLAN } from "../../../../shared/plans";

const premiumDataCardMessages = defineMessages({
    title: {
        id: 'PremiumDataCard.title',
        defaultMessage: 'Premium Data Downloads',
        description: 'Title'
    },
    description: {
        id: 'PremiumDataCard.description',
        defaultMessage: 'With 20,000 updates to our database every day, get the most up-to-date mapping data: buildings, roads, greenspaces, addresses, boundaries, and much more. Available to business and Government.',
        description: 'Description'
    },
    link: {
        id: 'PremiumDataCard.link',
        defaultMessage: 'Access Premium Data',
        description: 'Link'
    },
    imageDescription: {
        id: 'PremiumDataCard.imageDescription',
        defaultMessage: 'Unlabelled map of an urban area with points marked on buildings, streets and open spaces.',
        description: 'Image description'
    }
});

const OSSelectAndBuildCardMessages = defineMessages({
    title: {
        id: 'OSSelectAndBuildCard.title',
        defaultMessage: 'OS Select+Build',
        description: 'Title'
    },
    description: {
        id: 'OSSelectAndBuildCard.description',
        defaultMessage: 'Create your own customised data selections. Filter data by attribute, location and time, to power your applications with rich, consistent, and current information about the real world. Available to Public Sector and OS Partners.',
        description: 'Description'
    },
    link: {
        id: 'OSSelectAndBuildCard.link',
        defaultMessage: 'Learn about OS Select+Build',
        description: 'Link'
    },
    videoTitle: {
        id: 'OSSelectAndBuildCard.videoTitle',
        defaultMessage: 'OS Select+Build & OS NGD APIs',
        description: 'Video title'
    }
});

const OSOpenDataCardMessages = defineMessages({
    title: {
        id: 'OSOpenDataCard.title',
        defaultMessage: 'OpenData',
        description: 'Title'
    },
    description: {
        id: 'OSOpenDataCard.description',
        defaultMessage: 'Free digital maps and data. Integrate into your applications with our free APIs, or use the data in your geographic information system. Available to anyone, for any use.',
        description: 'Description'
    },
    link: {
        id: 'OSOpenDataCard.link',
        defaultMessage: 'Explore and access OpenData',
        description: 'Link'
    },
    imageDescription: {
        id: 'OSOpenDataCard.imageDescription',
        defaultMessage: 'Road network diagram.',
        description: 'Image description'
    }
});

const delDataCardMessages = defineMessages({
    title: {
        id: 'DelCard.title',
        defaultMessage: 'Try Premium Data for free',
        description: 'Title'
    },
    description: {
        id: 'DelCard.description',
        defaultMessage: 'Sample a small area of real data from our Premium Data product range, or apply for 6 months’ full access to Premium Data downloads (some conditions apply).',
        description: 'Description'
    },
    sampleLink: {
        id: 'DelCard.sampleLink',
        defaultMessage: 'Explore sample Premium Data',
        description: 'Link'
    },
    delLink: {
        id: 'DelCard.delLink',
        defaultMessage: 'Apply for 6 month free full Premium access',
        description: 'Link'
    },
    imageDescription: {
        id: 'DelCard.imageDescription',
        defaultMessage: 'Data Exploration License promotional image.',
        description: 'Image description'
    }
});

const createImageAttrData = (imgLarge, imgLargeWidth, imgSmall, imgSmallWidth) => {
    if (imgLarge && imgLargeWidth && imgSmall && imgSmallWidth) {
        return {
            srcSet: `${imgLarge} ${imgLargeWidth}w, ${imgSmall} ${imgSmallWidth}w`,
            sizes: `(min-width: ${singleColBreakpoint}px) calc(33.3vw - (${gridGap} * 2) - (${horizontalPadding} * 2)), calc(100vw - (${horizontalPadding} * 2))`
        }
    }
};

const OSOpenDataImageAttributes = createImageAttrData(openData, 1280, openDataSmall, 600);

export const DownloadData = () => {
    const intl = useIntl();
    const delEnabled = useFeatureCheck(features.DEL);
    const { visible: delVisible } = useDelEligibility();
    const user = useSelector(state => state.user?.current?.result);
    const isPartner = user?.plan === USER_PREMIUM_DATA_PLAN &&  hasPartnerCatalogue(user)

    return (
        <section>
            <CardContainer>
                <Card
                    imgSrc={accessPremium}
                    imgAltMessage={premiumDataCardMessages.imageDescription}
                    title={premiumDataCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h3'
                    description={premiumDataCardMessages.description}
                    link={<ExternalLink
                        message={premiumDataCardMessages.link}
                        href="https://www.ordnancesurvey.co.uk/products/search-for-os-products?plan=0ebf35ff-3ec6-4aa7-962c-e8eb6a4771c2" />}
                />

                <Card
                    video={<VideoContent videoId='rbsprbEa49Q' videoTitle={intl.formatMessage(OSSelectAndBuildCardMessages.videoTitle)} />}
                    title={OSSelectAndBuildCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h3'
                    description={OSSelectAndBuildCardMessages.description}
                    link={<ExternalLink
                        message={OSSelectAndBuildCardMessages.link}
                        href="https://docs.os.uk/osngd/accessing-os-ngd/downloading-with-os-select+build" />}
                />

                <Card
                    imgSrcSet={OSOpenDataImageAttributes.srcSet}
                    imgSizes={OSOpenDataImageAttributes.sizes}
                    imgAltMessage={OSOpenDataCardMessages.imageDescription}
                    title={OSOpenDataCardMessages.title}
                    titleVariant='h3'
                    titleComponent='h3'
                    description={OSOpenDataCardMessages.description}
                    link={<InternalLink
                        message={OSOpenDataCardMessages.link}
                        path="/downloads/open" />}
                />

                {!isPartner && (useFeatureCheck(features.SAMPLE_DATA) || (delEnabled && delVisible)) &&
                    <Card
                        imgSrc={del}
                        imgAltMessage={delDataCardMessages.imageDescription}
                        title={delDataCardMessages.title}
                        titleVariant='h3'
                        titleComponent='h3'
                        description={delDataCardMessages.description}
                        link={<>
                            {useFeatureCheck(features.SAMPLE_DATA) &&
                                <InternalLink message={delDataCardMessages.sampleLink} path="/downloads/sample" />}
                            {(delEnabled && delVisible) &&
                                <InternalLink message={delDataCardMessages.delLink} path="/downloads/del" />}
                        </>}
                    />
                }
            </CardContainer>
        </section>
    );
};

export default DownloadData;