import { defineMessages } from "react-intl";

const teamSpaceMessages = defineMessages({
    title: {
        id: "teamSpaceMessages.title",
        defaultMessage: "Team Space",
        description: "Team Space > Title",
    },
    backToTeamSpacesPage: {
        id: "teamSpaceMessages.backToTeamSpacesPage",
        defaultMessage: "Team Spaces",
        description: "Team Space > Back to Team Spaces page",
    },
    notFound: {
        id: "teamSpaceMessages.notFound",
        description: "Team Space > Error > Not Found",
        defaultMessage: "Team Space not found",
    },
    apiProjectsTab: {
        id: "teamSpaceAccessMessages.apiProjectsTab",
        defaultMessage: "API Projects",
        description: "API Projects tab",
    },
    addButton: {
        id: "teamSpaceAccessMessages.addButton",
        defaultMessage: "{actionText} {product}",
        description: "Add resource button for a Team Space ",
    },
    sortLabel: {
        id: "teamSpaceMessages.sortLabel",
        description: "API Projects Filter Controls > Sort Label",
        defaultMessage: "Sort",
    },
    sortLabelAria: {
        id: "teamSpaceMessages.sortLabelAria",
        description: "API Projects Filter Controls > Sort Label",
        defaultMessage: "API Projects Sort Options (Dropdown Menu)",
    },
    searchLabel: {
        id: "teamSpaceMessages.searchLabel",
        defaultMessage: "Search API Projects",
        description: "API Projects Filter Controls > Search Box Label",
    },
    searchPlaceholder: {
        id: "teamSpaceMessages.searchPlaceholder",
        defaultMessage: "Find an API Project by name...",
        description: "API Projects Filter Controls > Search Box Placeholder",
    },
    apiProjectsActionsAriaLabel: {
        id: "teamSpaceMessages.apiProjectsActionsAriaLabel",
        description: "Team Space > API project actions > Aria Label",
        defaultMessage: "API Project actions menu",
    },
    moveApiProject: {
        id: "teamSpaceMessages.moveApiProject",
        description: "Team Space > Move API Project",
        defaultMessage: "Move to another Team Space",
    },
    removeApiProject: {
        id: "teamSpaceMessages.removeApiProject",
        description: "Team Space > Remove an API Project from a Team Space",
        defaultMessage: "Remove from Team Space",
    },
    deleteApiProject: {
        id: "teamSpaceMessages.deleteApiProject",
        description: "Team Space > Delete an API Project and remove it from the Team Space",
        defaultMessage: "Delete API Project",
    },
    deletedApiProjectSuccess: {
        id: "teamSpaceMessages.deletedApiProjectSuccess",
        description: "Team Space > Deleted API Project",
        defaultMessage: "Deleted API Project",
    },
    addedApiProjectSuccess: {
        id: "teamSpaceMessages.addedApiProjectSuccess",
        description: "Team Space > Added API Project",
        defaultMessage: "Added API Project",
    },
    removedApiProjectSuccess: {
        id: "teamSpaceMessages.removedApiProjectSuccess",
        description: "Team Space > Deleted API Project",
        defaultMessage: "Removed API Project",
    },
    removedResourceSuccess: {
        id: "teamSpaceMessages.removedResourceSuccess",
        description: "Team Space > Removed Team Space Resource",
        defaultMessage: "Removed Team Space Resource success",
    },
    deleteTeamSpace: {
        id: "teamSpaceMessages.deleteTeamSpace",
        description: "Team Space > Delete Team Space",
        defaultMessage: "Delete Team Space",
    },
    deletedTeamSpaceSuccess: {
        id: "teamSpaceMessages.deletedTeamSpaceSuccess",
        description: "Team Space > Deleted Team Space",
        defaultMessage: "Deleted Team Space",
    },
    deletedTeamSpaceMovedApiProjectsSuccess: {
        id: "teamSpaceMessages.deletedTeamSpaceMovedApiProjectsSuccess",
        description: "Team Space > Deleted Team Space and moved the API projects",
        defaultMessage: "Deleted the Team Space. API Projects were moved to a new Team Space.",
    },
    searchHasResults: {
        id: "teamSpaceMessages.searchHasResults",
        description: "The total number of results",
        defaultMessage: "{total} result{isPlural}.",
    },
    searchHasNoResults: {
        // Note: This is not the "Empty State" message - it
        // only affects situations when result filters are in use.
        id: "teamSpaceMessages.searchHasNoResults",
        description: "The error message displayed when the user has no results.",
        defaultMessage: "No results. Please refine your search filters.",
    },
});

export default teamSpaceMessages;
