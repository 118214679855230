import { Box, Table, Typography } from "@mui/material";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import Link from "../../components/Link";
import { border1, osColour } from "omse-components";
import routePaths from "../../util/routes";
import { ReactComponent as ApiIcon } from "../../components/icons/api-large.svg";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { TeamResourceActionsMenu } from "./TeamResourceActionsMenu";
import apiProjectsTableMessages from "./ApiProjectsTable.msg";
import ProjectMode from "../../components/ProjectMode";
import { useSelector } from "react-redux";

const messages = apiProjectsTableMessages;

const ApiProjectsListItem = styled(Table)(
    ({ theme }) => `
        width: 100%;
        border: none;
        border-collapse: collapse;
        font-family: Source Sans Pro, sans-serif;

        ${theme.breakpoints.down("sm")} {
            & > th {
                display: none;
                border: none;
            }
            & > a {
                display: contents;
            }
            & > tr {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: left;
                min-width: contents;

                td:nth-child(4) {
                    display: none; /* Hide mode column on small screens */
                }
            }
            & > .tableContent {
                display: grid;
                grid-template-columns: 0.4fr 1fr;
                align-items: start;

                & > svg {
                    align-items: start;
                }
            }
        }

        th {
            background-color: ${osColour.primary.lightestBerry};
            border-bottom: 2px solid ${osColour.primary.berry};
            padding: ${theme.spacing(1)};
        }

        tr {
            text-align: left;
            border-bottom: ${border1};

            ${theme.breakpoints.up("lg")} {
                td:nth-child(1) {
                    max-width: 40vw; /* Restrict width of first column for long names */
                }
            }
        }

        td {
            padding: ${theme.spacing(1)};
        }

        .tableContent {
            display: flex;
            align-items: center;
            gap: ${theme.spacing(2)};
        }

        .detailContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
        }

        .moreHeading {
            align-items: center;
            justify-items: end;

        }
    `
);

function projectLink(apiProject) {
    return routePaths.project.replace(":projectId", apiProject.apiProjectId);
};

// Laying out the columns is easier if we have a single array that controls them - this allows us to write all the
// headers and then render the data for each project in turn.

const columns = [
    {
        heading: messages.apiProjectName,
        render: (apiProject, teamSpaceName, teamSpaceId) => (
            <div className="tableContent">
                <ApiIcon aria-hidden={true} width={44} height={48} />
                <div className="detailContainer">
                    <Link 
                        path={projectLink(apiProject)} 
                        state={{
                            prevLocation: routePaths.teamSpace.replace(":teamSpaceId", teamSpaceId), 
                            prevLocationLabel: teamSpaceName
                        }}
                    >
                        <Typography variant="body1">{apiProject.name}</Typography>
                    </Link>
                </div>
            </div>
        ),
    },
    {
        heading: messages.createdAt,
        render: (apiProject) => (
            <FormattedDate
                value={apiProject.createdAt}
                day="numeric"
                month="short"
                year="numeric"
            />
        ),
    },
    {
        heading: messages.mode,
        render: (apiProject) => <ProjectMode project={apiProject} allowChange={false} />,
    },
    {
        heading: messages.more,
        render: (apiProject, _, __, setRemoveTeamResourceState, setApiProjectMoveDialogState) => (
            <div className='moreHeading'>
                <TeamResourceActionsMenu
                    apiProject={apiProject}
                    setRemoveTeamResourceState={setRemoveTeamResourceState}
                    setApiProjectMoveDialogState={setApiProjectMoveDialogState}
                />
            </div>
        ),
    },
];

export default function ApiProjectsTable(props) {
    const user = useSelector((state) => state.user.current.result);
    const isPSGA = user.plan === 'PSGA';

    const { projectList, className, setRemoveTeamResourceState, setApiProjectMoveDialogState, teamSpaceName, teamSpaceId } =
        props;

    function renderHeadings() {
        const filteredColumns = isPSGA ? columns.filter((col) => col.heading.id !== 'ApiProjectsTable.mode') : columns;
        return (
            <tr>
                {filteredColumns.map((col) => (
                    <th key={col.heading.id} className={col.heading === messages.more ? 'moreHeading' : ''}>
                        <Typography variant="body2" color="primary">
                            <FormattedMessage {...col.heading} />
                        </Typography>
                    </th>
                ))}
            </tr>
        );
    }

    function renderRow(apiProject) {
        const filteredColumns = isPSGA ? columns.filter((col) => col.heading.id !== 'ApiProjectsTable.mode') : columns;
        return (
            <tr key={apiProject.apiProjectId}>
                {filteredColumns.map((col) => (
                    <td key={apiProject.projectId + col.heading.id}>
                        {col.render(
                            apiProject,
                            teamSpaceName,
                            teamSpaceId,
                            setRemoveTeamResourceState,
                            setApiProjectMoveDialogState,
                        )}
                        
                    </td>
                ))}
            </tr>
        );
    }

    return (
        <ApiProjectsListItem className={className}>
            <Typography variant="body1" component="tbody">
                {renderHeadings()}
                {projectList.map(renderRow)}
            </Typography>
        </ApiProjectsListItem>
    );
}

ApiProjectsTable.propTypes = {
    projectList: PropTypes.array.isRequired,
    setRemoveTeamResourceState: PropTypes.func.isRequired,
    setApiProjectMoveDialogState: PropTypes.func.isRequired,
};

