import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { InternalLink, ExternalLink, osColour, Notification } from "omse-components";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import ClickAwayTooltip from "../../../components/ClickAwayTooltip";
import SearchBox from "../../../components/SearchBox";
import { getTeamSpaces } from "../../../modules/teamSpaces/actions";
import routes from "../../../util/routes";
import teamResourcesMessages from "./TeamResources.msg";
import { clickAwayTooltipClass, clickAwayTooltipLinkClass } from "../shared/Styles";

const Container = styled.div(
    ({ theme }) => `
    & .loading {
        margin: ${theme.spacing(2)};
    }
    & > div > p {
        display: flex;
        color: ${osColour.neutral.stone};
        margin: ${theme.spacing(2, 0, 1, 0)};
    }
    & > div > div > input {
        background-color: white;
    }
    & > div > div > div {
        background-color: white;
    }
`
);

const CustomTableContainer = styled(TableContainer)`
    overflow-y: auto;
    min-height: 18dvh;
    max-height: 18dvh;

    @media screen and (min-height: 400px) {
        min-height: 46dvh;
        max-height: 46dvh;
    }

    @media screen and (min-height: 801px) and (max-width: 600px) {
        min-height: 67dvh;
        max-height: 67dvh;
    }

    @media screen and (min-height: 900px) {
        min-height: 30dvh;
        max-height: 30dvh;
    }

    @media screen and (min-height: 900px) and (max-width: 600px) {
        min-height: 70dvh;
        max-height: 70dvh;
    }

    @media screen and (min-height: 1000px) {
        min-height: 34dvh;
        max-height: 34dvh;
    }

    @media screen and (min-height: 1000px) and (max-width: 600px) {
        min-height: 76dvh;
        max-height: 76dvh;
    }
`;

const CustomTable = styled(Table)(
    ({ theme }) => `
    table-layout: fixed;
    border-collapse: collapse;
    & > thead > tr {
        border-bottom: 2px solid ${osColour.primary.berry};
    }

    & > thead > tr > th {
        color: ${osColour.primary.berry};
        font-weight: bold;
        border: none;
    }

    & > tbody > tr {
        border-bottom: 1px solid ${osColour.neutral.mist};
    }

    & > tbody > tr > td {
        font-weight: normal;
        border-bottom: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & > tbody > .disabled {
        background-color: ${osColour.neutral.clouds};
        cursor: not-allowed;
    }

    & > tbody > .empty {
        border-bottom: none;
    }

    & > tbody > .empty > td {
        padding: ${theme.spacing(2)}; 
    }

    & > tbody > tr > td > span {
        margin-right: 24px;
    }
`
);

const StyleCircularProgress = styled(CircularProgress)(
    ({ theme }) => `
    display: flex;
    margin: ${theme.spacing(4)} auto;
`
);

export default function TeamSpaceResources({ createTeamResourceError, setSelectedApiProjects, working }) {
    const intl = useIntl();

    const {
        result: apiProjects,
        loading: apiProjectsLoading,
        error: apiProjectsError,
    } = useSelector((state) => state.projects.current);

    const {
        result: teamSpaces,
        loading: teamSpacesLoading,
        error: teamSpacesError,
    } = useSelector((state) => state.teamSpaces.getTeamSpaces);

    const { result: teamSpace, loading: teamSpaceLoading } = useSelector(
        (state) => state.teamSpaces.getTeamSpace
    );

    const [searchTerm, setSearchTerm] = useState("");

    const filteredApiProjects = useMemo(() => {
        return apiProjects?.filter((apiProject) => {
            return apiProject.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [apiProjects, searchTerm]);

    const loading = apiProjectsLoading || teamSpacesLoading || teamSpaceLoading || working;
    const error = apiProjectsError || teamSpacesError || createTeamResourceError

    if (!loading && error) {
        return (
            <Notification variant="error" appearance="inline">
                <Typography variant="body1">
                    {intl.formatMessage(teamResourcesMessages.genericServerError, {
                        link: <ExternalLink type="support" />,
                    })}
                </Typography>
            </Notification>
        );
    }

    return (
        <Container>
            <SearchBox
                label={teamResourcesMessages.searchInputLabel}
                placeholder={teamResourcesMessages.searchPlaceholder}
                setSearch={setSearchTerm}
                search={searchTerm}
            />
            <div>
                <Typography variant="body1">
                    <FormattedMessage {...teamResourcesMessages.dialogToolTipLabel} />
                    <ClickAwayTooltip
                        id="ownerHelp"
                        classes={{
                            clickAwayTooltip: clickAwayTooltipClass,
                            tooltipLink: clickAwayTooltipLinkClass,
                        }}
                        icon={<InfoIcon width={24} height={24} color={osColour.status.warning} />}
                        body={<FormattedMessage {...teamResourcesMessages.dialogToolTipContent} />}
                        ariaLabel={intl.formatMessage(teamResourcesMessages.dialogToolTipLabel)}
                    />
                </Typography>
            </div>
            {loading && <StyleCircularProgress size={32} data-testid="loading-spinner" />}
            {!loading && !error && (
                <ApiProjectsTable
                    apiProjects={filteredApiProjects}
                    setSelectedApiProjects={setSelectedApiProjects}
                    teamSpaces={teamSpaces}
                    teamSpace={teamSpace}
                />
            )}
        </Container>
    );
}

TeamSpaceResources.propTypes = {
    setSelectedApiProjects: PropTypes.func,
    working: PropTypes.bool,
};

function ApiProjectsTable({ apiProjects, setSelectedApiProjects, teamSpaces, teamSpace }) {
    const intl = useIntl();

    const handleCheckboxChange = (event, apiProject) => {
        setSelectedApiProjects(apiProject);
    };

    const sortedApiProjects = useMemo(() => {
        const teamSpaceMap = new Map();

        teamSpaces?.forEach((space) => {
            space.teamResources?.apiProjects?.forEach((resource) => {
                teamSpaceMap.set(resource.apiProjectId, space.name);
            });
        });

        teamSpace?.teamResources?.apiProjects?.forEach((resource) => {
            teamSpaceMap.set(resource.apiProjectId, teamSpace.name);
        });

        const sortedApiProjects = apiProjects?.map((project) => {
            const teamSpace = teamSpaceMap.get(project.projectId) || null;
            return { ...project, teamSpace };
        });

        //  Unassigned projects, i.e. no Team Space, should be first
        //  then sort each alpabetically
        sortedApiProjects?.sort((a, b) => {
            if (!a.teamSpace && b.teamSpace) return -1;
            if (a.teamSpace && !b.teamSpace) return 1;
            return a.name.localeCompare(b.name);
        });

        // filter out projects assigned to current Team Space in view
        return sortedApiProjects?.filter((project) => project.teamSpace !== teamSpace.name) || [];
    }, [apiProjects, teamSpace]);

    return (
        <CustomTableContainer>
            <FormControl>
                <RadioGroup>
                    <CustomTable stickyHeader>
                        <TableHead>
                            <TableRow key={"table-row-head"}>
                                <TableCell>
                                    {intl.formatMessage(teamResourcesMessages.apiProject)}
                                </TableCell>
                                <TableCell>
                                    {intl.formatMessage(teamResourcesMessages.teamSpace)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody data-testid="apiprojects-table">
                            {sortedApiProjects.length === 0 && (
                                <TableRow className="empty">
                                    <Typography variant="body1" component={"td"} colSpan={2}>
                                        <FormattedMessage
                                            {...teamResourcesMessages.noApiProjects}
                                            values={{
                                                link: (
                                                    <InternalLink
                                                        path={routes.projects}
                                                        message={
                                                            teamResourcesMessages.noApiProjectsLink
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </TableRow>
                            )}
                            {sortedApiProjects?.map(
                                (apiProject) =>
                                    apiProject && (
                                        <TableRow
                                            key={apiProject.name}
                                            className={apiProject.teamSpace && "disabled"}
                                        >
                                            <TableCell>
                                                <FormControlLabel
                                                    value={apiProject.projectId}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(e, apiProject)
                                                    }
                                                    control={
                                                        <Radio disabled={apiProject.teamSpace} />
                                                    }
                                                />
                                                {apiProject?.name}
                                            </TableCell>
                                            <TableCell>
                                                {apiProject.teamSpace ? (
                                                    apiProject.teamSpace
                                                ) : (
                                                    <span style={{ fontStyle: "italic" }}>
                                                        {intl.formatMessage(
                                                            teamResourcesMessages.notAssignedTeamSpace
                                                        )}
                                                    </span>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </CustomTable>
                </RadioGroup>
            </FormControl>
        </CustomTableContainer>
    );
}

ApiProjectsTable.propTypes = {
    apiProjects: PropTypes.array,
    teamSpaces: PropTypes.array,
    selectedApiProjects: PropTypes.array,
    setSelectedApiProjects: PropTypes.func,
};
