import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactComponent as LeftArrow } from "./icons/backward-arrow-large.svg";
import Link from "./Link";
import backLinkMessages from "./BackLink.msg";

const StyledLink = styled(Link)(
    ({ theme }) => `
    margin-top: ${theme.spacing(4.5)};
    margin-left: ${theme.spacing(3)};
    display: inline-flex;
    align-items: center;

    ${theme.breakpoints.down("sm")} {
        margin-left: ${theme.spacing(2)},
    }

    & > svg {
        width: 16px;
        height: 16px;
        padding-top: 2px;
        padding-right: ${theme.spacing(1)};
    }
`
);

function BackLink(props) {
    const { path, label, state } = props;
    const intl = useIntl();

    const ariaLabel = intl.formatMessage(backLinkMessages.backIconAlt);
    const textLabel =
        typeof label === "string"
            ? label
            : intl.formatMessage(label, {
                  ...label.values,
              });

    return (
        <nav>
            <StyledLink path={path} state={state}>
                <LeftArrow aria-label={ariaLabel} />
                <Typography variant="body1">{textLabel}</Typography>
            </StyledLink>
        </nav>
    );
}

BackLink.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    state: PropTypes.object,
};

export default BackLink;
